import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1><a parentName="h1" {...{
        "href": "http://bioteksa.com"
      }}>{`Bioteksa`}</a></h1>
    <h2>{`Summary`}</h2>
    <p>{`After completing the core logic for biobot, one of the board meambers approached me
to build some microservices for their parent company. The company had acquired biobot
a few months earlier and needed software for their administrative needs.`}</p>
    <p>{`I ended up building six microservices that have since become a key aspect of the company's
workflow to get business metrics and insights. The services included a data warehouse with
information on invoices, clients, transactions and different crops planted and sold throughout
México.`}</p>
    <p>{`After finishing this project, the company decided to implement a new ERP
software to handle repetitive tasks performed by workers. I was given the
responsibility of architecting the SSO and communication protocols between
all the programs, which was a significant challenge that
I was passionate about tackling.`}</p>
    <p>{`Additionally, I was tasked with updating the API of biobot.farm as it transitioned
from B2C to B2B. It was an exciting opportunity to apply my skills and experience
in a new context, and I was thrilled to take it on.`}</p>
    <TechTags title="Jobs" tags={["Main Backend Dev", "Software Architecture", "Mentoring", "Devops"]} mdxType="TechTags" />
    <h2>{`Challenges`}</h2>
    <p>{`The journey had a lot of challenges and these are some of them. They aren't in order of difficulty`}</p>
    <ul>
      <li parentName="ul">{`Developing my first CLI program`}</li>
      <li parentName="ul">{`Creating an OAuth Server`}</li>
      <li parentName="ul">{`AWS Lambda config`}</li>
      <li parentName="ul">{`Multiple API comunications`}</li>
      <li parentName="ul">{`Pipelines for continuous development`}</li>
    </ul>
    <TechTags title="Technologies" tags={["Python", "Docker", "AWS Lambda", "cli", "Vue.js", "FastAPI", "postgreSQL", "Mysql", "AWS", "heroku", "Pipelines", "Flutter", "nginx", "S3"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      